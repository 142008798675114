<template>
  <div class="decision-manual-inspection">
    <Container>
      <Card padding="large">
        <b-row>
          <b-col cols="12" sm="auto" class="text-center">
            <ProcessingSVG class="icon h1" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">{{ $t('MANUAL_INSPECTION_INFO') }}</h1>
              <p class="text-small">{{ $t('MANUAL_INSPECTION_INFO_2') }}</p>
            </Margins>
          </b-col>
        </b-row>

        <Separator size="large" />
        <div class="text-center">
          <Button
            @click="
              $router.push({
                name: ROUTES.LOAN_PAYMENT_LOADING.name,
              })
            "
          >
            {{ $t('REFRESH') }}
          </Button>
        </div>
      </Card>
    </Container>
  </div>
</template>

<script>
import { Container, Card, Margins, Separator, Button } from '@/components';
import ProcessingSVG from '@/assets/images/processing.svg';
import { constants } from '@/mixins';

export default {
  name: 'ManualInspection',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Separator,
    Button,
    ProcessingSVG,
  },
};
</script>
